<template>
  <div>
    <a class="come-back" @click.prevent="$router.go(-1)"><div></div>Вернуться назад</a>
    <h2 class="title_categories">
      {{ isEdit ? `Редактирование вопроса №${$route.params.id}` : 'Создание вопроса' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createAdmin" ref="createAdmin">
        <div>
          <div class="content-sb">
            <p>Вопрос<span>*</span></p>
            <input required v-model="dataForm.question" placeholder="Введите заголовок" type="text" name="name" class="input blue">
          </div>

          <div class="content-sb">
            <p>Ответ<span>*</span></p>
            <ckeditor :config="$editorConfig" :editor-url="$root.editorUrl" v-model="dataForm.answer" required></ckeditor>
          </div>
        </div>

        <footer class="content-sb btns-create-form">
          <router-link to="/admin/asked_questions" class="btn-white">Отмена</router-link>
          <input type="submit" class="btn-blue" value="Сохранить"/>
        </footer>
    </form>

  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
export default {
  name: 'CreateAskedQuestions',
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      dataForm: {
        question: '',
        answer: ''
      }
    }
  },
  mounted () {
    if (this.isEdit) {
      this.$store.dispatch('setDetailedQuestion', this.$route.params.id)
    }
  },
  watch: {
    '$store.getters.getDetailedQuestion' () {
      const dataFromServer = this.$store.getters.getDetailedQuestion

      this.dataForm = {
        question: dataFromServer.question,
        answer: dataFromServer.answer
      }
      this.$forceUpdate()
    }
  },
  methods: {
    createAdmin () {
      const formData = new FormData()

      for (const key in this.dataForm) {
        formData.append(key, this.dataForm[key])
      }
      if (!this.isEdit) {
        this.$store.dispatch('createQuestionAdmin', formData)
      } else {
        this.$store.dispatch('updateQuestionAdmin', [formData, this.$store.getters.getDetailedQuestion.id])
      }
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
.select, .input{
  width: 33.75rem;
}
.content-sb{
  margin-bottom: 1.25rem;
  span{
    color: red;
  }
}
.wrapper-blog{
  margin-top: 1.25rem;
}
.btns-create-form{
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}
</style>
